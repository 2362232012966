import { useEffect, useMemo, useRef, useState } from 'react'

import {
  getDateMonthConverted,
  numberWithCommas,
  showErrorToast,
  pushDataLayerEventHandler,
  formatInfathResponse,
  amountWithSar,
  numberWithArabicCommas,
} from '@/utils/utils'
import moment from 'moment'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import Attributes from './attributes'
import CardHeader from './cardHeader'
import CardFooter from './CardFooter'
import styles from './style.module.scss'
import { ALL_SELLER_TYPE } from '@/utils/constant'
import { useRouter } from 'next/router'
import IconoGraphy from '@/components/Common/IconoGraphy'
import { fetchApi } from '@/utils/apiCall'
import EWalletAuctionTransactionDetailPopup from '@/components/EWallet/EWalletAuctionTransactionDetailPopup'
import { useMediaQuery } from 'react-responsive'
import InfathAttributes from './infathAttributes'
import Timer from '../../FrameTimer'
import Tooltip_new from '../../Tooltip/tooltip_new'
import { useClickOutsideListenerRef } from '@/utils/useClickOutsideListener'
import AuctionRejectEditPopup from '@/components/AuctionRejectEditPopup'
import Image from 'next/image'

const AuctionCard = ({
  auctionData,
  showStrip,
  getAcutionPropertyDetails,
  City_Selected,
  Tab_Selected = null,
  hasFollowed,
  refetchAllAuctions,
  handleScrollPositionClick,
  sellerType,
  isRegisteredCard = false,
  isPageTypeProfile = false,
  auctionType,
  refetchFollowedAuctions,
  isDesktopSSR = null,
  cardIndex,
}) => {
  const { t, lang } = useTranslation('translations-auction')
  const router = useRouter()
  const data = formatInfathResponse(auctionData, lang, sellerType)
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [ShowTransitionCards, setShowTransitionCards] = useState(false)
  const [TransitionData, SetTransitionData] = useState(null)
  const [tooltipStyles, setTooltipStyles] = useState(null)
  const [isHovering, setIsHovering] = useState(false)
  const [linkTarget, setLinkTarget] = useState('_blank')
  const [isEditModeEnable, setIsEditModeEnable] = useState({})
  const [showEditRejectPopup, setShowEditRejectPopup] = useState(false)
  const isCaptured = data?.transactionStatus == 'captured'
  const isSellerInfath = data?.seller === ALL_SELLER_TYPE.INFATH
  const isInfathRegistrationCard = data?.seller === ALL_SELLER_TYPE.INFATH
  const isAwaitingResult = isCaptured && data?.subStatusType !== 'sub-auction-closed-sold'
  const wonAuction = isCaptured && data?.subStatusType === 'sub-auction-closed-sold'
  const disabledDiv = () => (isEditModeEnable?.isEditing || isEditModeEnable?.status === 'awaiting-rejected') ? styles.disabledDiv : ''

  const renderEndDate = useMemo(() => {
    return getDateMonthConverted(t, moment(data?.endDate).format('DD MMM YYYY'), data?.endDate)
    // Add more JSX here if needed
  }, [auctionData])

  const renderCancelDate = useMemo(() => {
    return getDateMonthConverted(t, moment(data?.cancelDate).format('DD MMM YYYY'), data?.cancelDate)
    // Add more JSX here if needed
  }, [auctionData])

  const onScroll = () => {
    setIsHovering(false)
  }

  useEffect(() => {
    setLinkTarget(isMobile ? '_self' : '_blank')
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const CloseEditPopUp = () => {
    setShowEditRejectPopup(false)
  }

  useEffect(() => {
    setIsEditModeEnable({'isEditing': auctionData?.isEditing, 'status': auctionData?.status, callBackUrl: null})
  }, [auctionData])

  const refAddressTooltip = useClickOutsideListenerRef(() => {
    if (!isMobile) return
    setIsHovering(false)
  })

  const getActiveBiddersCount = () => data?.activeBidders > 1 ? data?.activeBidders : ''

  const getAttributes = () => {
    let attributes = []
    let attribute = {}
    if (isSellerInfath) {
      attribute = {
        key: 'noOfProperties',
        name: 'noOfProperties',
        value: data?.auctionItems,
        image: 'https://assets-dev.daralarkanonline.com/others/icons/home.png',
        iconClass: 'home',
      }
      attributes = [...attributes, attribute]
      if (data?.startDate) {
        attribute = {
          key: 'start-date',
          name: 'calendar-month',
          value: `sssss`,
          image: 'https://assets-dev.daralarkanonline.com/others/icons/calendar-month.png',
          iconClass: 'calendar-month',
          startDate: data?.startDate,
          endDate: data?.endDate,
        }
        attributes = [...attributes, attribute]
      }
    }
    return attributes
  }

  const getAuctionTypeText = (auctionStatus) => {
    switch (auctionStatus) {
      case 'past':
        return t('auctionStatus.CLOSED')?.toLowerCase()
      case 'cancel':
        return t('auctionStatus.CANCELLED')?.toLowerCase()
      case 'upcoming':
      case 'awaiting-rejected':
        return t('auctionStatus.UPCOMING')?.toLowerCase()
      case 'live':
        return (
          <>
            {t('auctionStatus.LIVE')?.toLowerCase()}{' '}
            {getActiveBiddersCount() && (
              <div className={styles.liveBidCount}>
                <span className={styles.liveBidCountArabic}>
                  <small className={styles.bidCount}>{getActiveBiddersCount()}</small>{' '}
                  {getActiveBiddersCount() && t('auctionHistory.BIDDERS_2')}
                </span>
              </div>
            )}
          </>
        )
      case 'featured':
        return `${t('auctionCommon.FEATURED')?.toLowerCase()} ${t('auctionCommon.AUCTION_SMALL')?.toLowerCase()}`
      case 'trending':
        return `${t('auctionCommon.TRENDING')?.toLowerCase()} ${t('auctionCommon.AUCTION_SMALL')?.toLowerCase()}`
      default:
        break
    }
  }

  const getBidHeaderText = (auctionStatus) => {
    switch (auctionStatus?.toLowerCase()) {
      case 'past':
        return !data?.currentHighestBid ? t('auctionBidComponent.STARTING_BID') : t('auctionBidComponent.HIGHEST_BID')
      case 'upcoming':
        return t('auctionBidComponent.STARTING_BID')
      case 'live':
        return t('auctionBidComponent.HIGHEST_BID')
      default:
        break
    }
  }
  const getBidHeaderTextEwallet = (auctionStatus) => {
    switch (auctionStatus?.toLowerCase()) {
      case 'past':
        return t('auctionBidComponent.HIGHEST_BID')
        case 'cancel':
          return t('auctionBidComponent.HIGHEST_BID')
      case 'upcoming':
        return data?.bidStartingPrice ? t('auctionBidComponent.STARTING_BID') : t('auctionGroupDetail.depositAmount')
      case 'live':
        return !data?.currentHighestBid ? t('auctionBidComponent.STARTING_BID') : t('auctionBidComponent.CURRENT_BID')
      default:
        break
    }
  }

  /**
   *
   *@12-10-22
   * Author- Wasalt Tech Team
   * Transaction Details for Hyper Pay / EWallet as per updated flow.
   */

  const getTransactionDetails = async (e) => {
    // Added this condition to make the button unclickable when the status is 'awaiting result'."
    if (isInfathRegistrationCard && isAwaitingResult) return

    e.preventDefault()
    e.stopPropagation()
    try {
      if (!data?.auctionId) throw new Error('Invalid auctionId')
      const response = await fetchApi({
        lang,
        baseUrl: 'NEXT_PUBLIC_GATEWAY_URL',
        subUrl: `transaction-details?auctionId=${data?.auctionId}&id=${data?.id}`,
        method: 'POST',
        reqBody: {
          auctionId: data?.auctionId,
          id: data?.id,
        },
      })
      if (response.data.status === true) {
        let transactionData = response.data?.data
        if (data?.paymentsourceType != '9') {
          transactionData = {
            ...transactionData,
            transactionHistory: transactionData?.transactionHistory.filter(
              (transHistory) => transHistory?.status !== 'initiated',
            ),
          }
        }
        SetTransitionData(transactionData)
        setShowTransitionCards(true)
      } else {
        showErrorToast(response.data.message)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const showTransactionHandler = () => {
    setShowTransitionCards(!ShowTransitionCards)
  }

  const refetchData = () => {
    if (isSellerInfath) {
      getAcutionPropertyDetails()
      if (refetchAllAuctions) {
        refetchAllAuctions()
      }
    } else {
      setTimeout(() => {
        getAcutionPropertyDetails()
        if (refetchAllAuctions) {
          refetchAllAuctions()
        }
      }, [1000])
    }
  }

  const getCurrentHighestBid = () => data.currentHighestBid ? data.currentHighestBid : data.bidStartingPrice

  const handleClick = (e) => {
    if (isEditModeEnable?.isEditing || isEditModeEnable?.status === 'awaiting-rejected') {
      e.preventDefault()
      setShowEditRejectPopup(true)
      return;
    }
    handleScrollPositionClick?.()
    if (City_Selected && Tab_Selected) {
      const biddingAmount = (data.status == 'live' || data.status == 'past') ? getCurrentHighestBid() : data.bidStartingPrice ?? 0
      pushDataLayerEventHandler(window, lang, 'Auction_Card_Click', 'Auction Home', {
        Auction_ID: data.auctionId,
        Auction_Status: data?.status,
        Registration_window_status: data.isRegistrationOpen ? 'opened' : 'closed',
        Bid_Amount: new Intl.NumberFormat('en-US', {
          maximumSignificantDigits: 9,
        }).format(biddingAmount,),
        Property_Info: data.property,
      })
    }
    window.localStorage.setItem('prevPath', router.asPath)
  }

  const containerRef = useRef(null)

  // Code to fix cityname tooltip issue on auction card
  useEffect(() => {
    if (containerRef.current && !tooltipStyles) {
      const mainRact = containerRef.current?.getBoundingClientRect()
      const tooltipElement = document.getElementById(`tooltip_new${data?.id}`)
      const tooltipElementBounds = tooltipElement?.getBoundingClientRect()

      let style = null

      if (tooltipElementBounds) {
        if (
          tooltipElementBounds.x < mainRact.x ||
          tooltipElementBounds.x + tooltipElementBounds.width > mainRact.x + mainRact.width
        ) {
          style = getTooltipStyle(tooltipElementBounds, mainRact)
        } else {
          style = { visibility: 'visible' }
        }

        tooltipElementBounds ? setTooltipStyles({ ...style }) : setTooltipStyles(null)
      }
    }
  }, [isHovering])

  const getTooltipStyle = (tooltipElementBounds, mainRact) => (
      {
        left: tooltipElementBounds?.x < mainRact?.x ? '45px' : 'auto',
        right: tooltipElementBounds?.x < mainRact?.x ? 'auto' : '45px',
        top: '15px',
        transform: 'translateY(-50%)',
        visibility: 'visible',
        arrowStyle:  tooltipElementBounds?.x < mainRact?.x
                      ? {
                          transform: 'translateY(-50%) rotate(-90deg)',
                          right: 'auto',
                          top: '50%',
                          left: '-12px',
                        }
                      : {
                          transform: 'translateY(-50%) rotate(90deg)',
                          right: '-12px',
                          top: '50%',
                          left: 'auto',
                        },
      }
  ) 

  const stopMobileRouting = (e) => {
    if (!isMobile) return
    e.preventDefault()
  }

  const handleTooltip = (e) => {
    if (!isMobile) return
    e.stopPropagation()
    e.preventDefault()
    setIsHovering(!isHovering)
  }

  const getIconClassName = () => (
    lang == 'ar' ? 'icon-chevron-left' : 'icon-chevron-right'
  )

  const getFooterBlurClassName = () => (isEditModeEnable?.isEditing || isEditModeEnable?.status === 'awaiting-rejected') ? styles.blurFooter : ''
  const getEditCardAndFooterPaddingClassName = (type='editCard') => {
    if ((isEditModeEnable?.isEditing || isEditModeEnable?.status === 'awaiting-rejected') && !isDesktopSSR) {
      return type === 'btmPad' ? styles.cardBtmPad : styles.editCard
    }
    return '';
  }

  const renderUpcomingAuctionStrip = () => { 
    const registrationStartDate = data?.registrationStartDate || data?.startDate
    const dateFormat = lang == 'ar' ? 'DD MMM YYYY' : 'Do MMM YYYY'
    const upcomingAuction = data.isRegistrationOpen || data?.seller === 'au-infath' ? (
      <>
        <IconoGraphy
          iconClass={'icon-auction-outline'}
          iconColor={'color_white'}
          fontSize={'f_20'}
        />
        <h1 className={styles.below_strip_heading}>
          {t('auctionBidComponent.REGISTRATION IS')} &nbsp;<b> {t('auctionBidComponent.OPEN')}</b>
        </h1>
      </>
    ) : (
      <>
        <IconoGraphy
          iconClass={'icon-auction-filled'}
          iconColor={'color_white'}
          fontSize={'f_20'}
        />
        <h1 className={styles.below_strip_heading}>
          {t('auctionBidComponent.REGISTRATION_STARTS_FROM')}&nbsp;
          <b>
            {' '}
            {getDateMonthConverted(
              t,
              moment(registrationStartDate).format(dateFormat,),
              registrationStartDate,
            )}
          </b>
        </h1>
      </>
    )

    return ( data.transactionStatus == 'on_hold' ? (
      <div className={styles.below_strip} onClick={getTransactionDetails}>
        <IconoGraphy iconClass={'icon-padlock'} iconColor={'color_white'} fontSize={'f_20'} />
        <h1 className={styles.below_strip_heading}>
          {amountWithSar(t, numberWithArabicCommas(data.bidDeposit, lang), lang, 'upcoming')}
          <IconoGraphy
            iconClass={getIconClassName()}
            iconColor={'color_white'}
            fontSize={'f_10'}
          />
        </h1>
      </div>
    ) : (
      <div className={styles.color_inherit}>
        <div className={styles.below_strip}>
          {upcomingAuction}
        </div>
      </div>
    )
    )
  }  

  const renderliveAuctionStrip = () => (
    data.transactionStatus == 'on_hold' ? (
      <div className={styles.below_strip} onClick={getTransactionDetails}>
        <IconoGraphy iconClass={'icon-padlock'} iconColor={'color_white'} fontSize={'f_20'} />
        <h1 className={styles.below_strip_heading}>
          {amountWithSar(t, numberWithArabicCommas(data.bidDeposit, lang), lang, 'live')}

          <IconoGraphy
            iconClass={getIconClassName()}
            iconColor={'color_white'}
            fontSize={'f_10'}
          />
        </h1>
      </div>
    ) : (
      <div className={styles.below_strip}>
        <IconoGraphy iconClass={'icon-auction-outline'} iconColor={'color_white'} fontSize={'f_20'} />
        <h1 className={styles.below_strip_heading}>
          {t('auctionHome.VIEW_AND_BID')}
          <IconoGraphy
            iconClass={getIconClassName()}
            iconColor={'color_white'}
            fontSize={'f_10'}
          />
        </h1>
      </div>
    )
  )

  const renderCapturedAuctionStrip = () => {
    const wonAuctionIconClassName = wonAuction ? 'icon-trophy' : 'icon-clock'
    const wonAuctionText = wonAuction ? t('Details.WON_AUCTION') : t('auctionGroupDetail.awaitingAuctionResult')
    const infathAuctionClassName = wonAuction ? styles.winner_strip : null
    return (
      <div
        className={`${styles.below_strip} ${isInfathRegistrationCard ? infathAuctionClassName : styles.winner_strip}`}
        onClick={getTransactionDetails}
      >
        {isInfathRegistrationCard ? (
          <IconoGraphy
            iconClass={wonAuctionIconClassName}
            iconColor={'color_white'}
            fontSize={'f_20'}
          ></IconoGraphy>
        ) : (
          <IconoGraphy
            iconClass={'icon-trophy'}
            iconColor={'color_white'}
            fontSize={'f_20'}
          ></IconoGraphy>
        )}
        <div>
          <div className={styles.below_strip_heading}>
            {isInfathRegistrationCard ? (
              <>
                {wonAuctionText}
              </>
            ) : (
              t('Details.WON_AUCTION')
            )}
            {(!isAwaitingResult || !isInfathRegistrationCard) && (
              <IconoGraphy
                iconClass={getIconClassName()}
                iconColor={'color_white'}
                fontSize={'f_10'}
              />
            )}
          </div>
        </div>
      </div>
    )
  }

  const renderPastHoldAuctionStrip = () => {
    const englishSAR = lang == 'en' ? `${t('transactionPopup.SAR')}` : ''
    const arabicSAR = lang == 'ar' ? `${t('transactionPopup.SAR')}` : ''
    return (
      <div className={styles.below_strip}>
        {isInfathRegistrationCard ? (
          <>
            <IconoGraphy
              iconClass={'icon-auction-reverse'}
              iconColor={'color_white'}
              fontSize={'f_20'}
            />
            <h1 className={styles.below_strip_heading}>
              {t('auctionGroupDetail.refundSoon')}
            </h1>
          </>
        ) : (
          <>
            <IconoGraphy iconClass={'icon-padlock'} iconColor={'color_white'} fontSize={'f_20'} />
            <h1 className={styles.below_strip_heading}>
              {englishSAR} {numberWithCommas(data.bidDeposit)}{' '}
              {arabicSAR} {t('transactionPopup.IS_BLOCKED')}{' '}
              <IconoGraphy
                iconClass={getIconClassName()}
                iconColor={'color_white'}
                fontSize={'f_10'}
              />
            </h1>
          </>
        )}
      </div>
    )
  }

  const getPastAuctionStrip = () => {
    if (data.transactionStatus === 'captured') {
      return renderCapturedAuctionStrip();
    } else if (data.transactionStatus === 'on_hold') {
      return renderPastHoldAuctionStrip();
    } else {
      return (
        <div className={styles.below_strip}>
          <IconoGraphy iconClass={'icon-warning'} iconColor={'color_white'} fontSize={'f_20'} />
          <h1 className={styles.below_strip_heading}>{t('propertyDetails.closedAuction')}</h1>
        </div>
      );
    }
  };

  const getCancelledAuctionStrip = () => {
    return (
      <div className={styles.below_strip}>
        <IconoGraphy iconClass={'icon-warning'} iconColor={'color_white'} fontSize={'f_20'} />
        <h1 className={`${styles.below_strip_heading} ${styles.below_strip_heading_cancel}`}>{t('propertyDetails.cancelledAuction')}</h1>
        <IconoGraphy iconClass={lang==='en' ? 'icon-chevron-right' : 'icon-chevron-left'} iconColor={'color_white'} fontSize={'f_10'} />
      </div>
    )
  }

  const renderPastAuctionStrip = () => {
    return (
      data.transactionStatus == 'refunded' ? (
        <div className={styles.below_strip} onClick={getTransactionDetails}>
          <IconoGraphy iconClass={'icon-reverse'} iconColor={'color_white'} fontSize={'f_20'} />
          <h1 className={styles.below_strip_heading}>
            {amountWithSar(t, numberWithArabicCommas(data.bidDeposit, lang), lang, 'past')}
            <IconoGraphy
              iconClass={getIconClassName()}
              iconColor={'color_white'}
              fontSize={'f_10'}
            />
          </h1>
        </div>
      ) : getPastAuctionStrip()
    )
  }  

  const renderEditStrip = () => (
    <div className={styles.EditStrip_Wrapper}>
      <IconoGraphy iconClass="icon-toast-info" iconColor="color_Edit_warning" fontSize="f_16"/>
      <div className={styles.EditStrip_Text_Wrapper}>
        <div className={styles.EditStrip_heading}>{t('EditFlow.Edit_Strip_Title')}</div>
        <div className={styles.EditStrip_below_heading}>{t('EditFlow.Edit_Strip_Text')}</div>
      </div>
  </div>
  )

  const renderStrip = () => {
    if (isEditModeEnable?.isEditing || isEditModeEnable?.status === 'awaiting-rejected') {
      return renderEditStrip()
    }
    let conditionalStrip  = null;

    if (data?.status?.toLowerCase() === 'live') {
      conditionalStrip =  renderliveAuctionStrip()
    } else if (data?.status?.toLowerCase() === 'past') {
      conditionalStrip = renderPastAuctionStrip()
    }  else if (data?.status?.toLowerCase() === 'cancel') {
      conditionalStrip = getCancelledAuctionStrip()
    }

    return (
      (showStrip ||
        ['auction-upcoming', 'auction-registered', 'auction-all', undefined].includes(router.query.tabType)) && (
        <div>{data?.status?.toLowerCase() == 'upcoming' ? renderUpcomingAuctionStrip() : conditionalStrip}</div>
      ))
  }

  const getHref  = () => {
    if(isSellerInfath) {
      return `/auction${router.route === '/' ? '-group' : ''}/${data.id}`
    }
    return `/auction/${data?.id}`
  }

  const getRightContentForFooter = () => {
    const direction = lang == 'ar' ? 'rtl' : 'ltr'
    let getTimer = (
      <span className={styles.price__auction_time}>
        <Timer
          leftOverMs={data?.leftOverMs}
          leftOverServerMs={data?.leftOverServerMs}
          refetchData={refetchData}
          currentAuctionStatus={data?.status?.toLowerCase()}
          isAuctionCard={true}
        />
      </span>
    )
    if (data?.status?.toLowerCase() == 'past') {
      getTimer = (
        <div>
          <div className={`${styles.closedDate}`}>
            <span key={data.endDate} id={`time_${data.auctionId}`}>
              {renderEndDate}
            </span>
          </div>
        </div>
      )
    } else if (data?.status?.toLowerCase() == 'cancel') {
      getTimer = (
        <div>
          <div className={`${styles.closedDate}`}>
            <span key={data.cancelDate} id={`time_${data.auctionId}`}>
              {renderCancelDate}
            </span>
          </div>
        </div>
      )
    }

    const privateAuctionContent = data.sellerSlug !== ALL_SELLER_TYPE.INFATH ? (
      <div
        className={`${styles.price} ${data?.status?.toLowerCase() == 'past' && styles.grid_gap_0}`}
        dir={direction}
      >
        {getTimer}
      </div>
    ) : (
      <div className={styles.price} dir='ltr'>
        {lang == 'ar' && (<span className={styles.price__currency}>{t('transactionPopup.SAR')}</span>)}
        <span className={styles.price__value}>
          {new Intl.NumberFormat('en-US', {
            maximumSignificantDigits: 9,
          }).format(data?.bidDeposit)}
        </span>
        {lang == 'en' && (<span className={styles.price__currency}>{t('transactionPopup.SAR')}</span>)}
      </div>
    )

    return (
      <div className={styles.auction_ends_time}>
        {/* Added Condition on Deposit Bid Amount and Auction starts/closed date/time section of card on the basis of seller Type */}
        {isPageTypeProfile && data?.seller === 'au-infath' ? (
          <div className={styles.price} dir='ltr'>
            {lang == 'ar' && (<span className={styles.price__currency}>{t('transactionPopup.SAR')}</span>)}
            <span className={styles.price__value}>
              {new Intl.NumberFormat('en-US', {
                maximumSignificantDigits: 9,
              }).format(data?.bidDeposit)}
            </span>
            {lang == 'en' && (<span className={styles.price__currency}>{t('transactionPopup.SAR')}</span>)}
          </div>
        ) : privateAuctionContent}
      </div>
    )
  }

  const getLeftContentForFooter = () => {
    let price;
    if (router.route === '/' ? isSellerInfath : data?.sellerSlug === ALL_SELLER_TYPE.INFATH) {
      price = data?.sDepAmt
    } else if (data.status == 'live' || data.status == 'past') {
      price = getCurrentHighestBid()
    } else if (data?.bidStartingPrice > 0) {
      price = data?.bidStartingPrice
    } else {
      price = data?.bidDeposit;
    }
    
    return (
      <div className={styles.price} dir='ltr'>
      {lang == 'ar' && <span className={styles.price__currency}>{t('transactionPopup.SAR')}</span>}
      <span className={styles.price__value}>
        {new Intl.NumberFormat('en-US', {
          maximumSignificantDigits: 9,
        }).format(price,)}
      </span>
      {lang == 'en' && <span className={styles.price__currency}>{t('transactionPopup.SAR')}</span>}
    </div>
    )
  }

  const rightHeaderTextForFooter = () => {
    if (isPageTypeProfile && data?.seller === 'au-infath') {
      return t('auctionGroupDetail.depositAmount')
    } else if (data.sellerSlug !== ALL_SELLER_TYPE.INFATH) {
      if(data.status == 'upcoming') {
        return t('auctionBidComponent.AUCTION_STARTS_IN')
      } else if (data.status == 'live') {
        return t('auctionHome.AUCTION_ENDS_IN')
      }
      else if (data.status == 'cancel') {
        return t('auctionHome.AUCTION_CANCELLED_ON')
      }
      return t('auctionHome.AUCTION_CLOSE_ON')
    }
    return t('auctionGroupDetail.depositAmount')
  }

  const leftHeaderTextForFooter = () => {
    if (isPageTypeProfile) {
      return getBidHeaderTextEwallet(data?.status)
    } else if (data.seller === ALL_SELLER_TYPE.INFATH) {
      return t('auctionBidComponent.DEPOSIT_STARTS_FROM')
    }
    return getBidHeaderText(data.status)
  }

  const renderAddress = () => (
    data?.address.length > 4 ? (
      <span className={styles.titleBox}>
        <span>{`${data?.address.slice(0, 4).join(', ')} `}</span>
        <span
          key={data?.id}
          ref={refAddressTooltip}
          className={styles.TooltipWrap}
          onClick={handleTooltip}
          onMouseEnter={() => (isMobile ? null : setIsHovering(true))}
          onMouseLeave={() => (isMobile ? null : setIsHovering(false))}
        >
          <span className={styles.TooltipButton}> +</span>
          <span className={styles.TooltipNumber}>{data?.address.length - 4}</span>

          {isHovering && (
            <span key={data?.id} className={styles.Tooltip_auction}>
              <Tooltip_new
                id={data?.id}
                elementStyles={tooltipStyles || {}}
                dynamicTooltip={true}
                message={data?.address.slice(4).join(', ')}
                dir='ltr'
              />
            </span>
          )}
        </span>
      </span>
    ) : (
      data?.address.join(', ')
    )
  )

  const getInfathAuctionIcon  = () => isRegisteredCard ? styles.infathAuctionIcon : null

  return (
    <>
      <div className={`${styles.cursor_pointer} ${disabledDiv()}`} onClick={handleClick}>
        <Link
          href={getHref()}
          passHref
          legacyBehavior
        >
          <a target={linkTarget}>
            <div className={`${styles.card} ${getEditCardAndFooterPaddingClassName()}`}>
              <CardHeader
                hasFollowed={hasFollowed}
                styles={styles}
                id={data.auctionId}
                status={data.status}
                tags={[data?.ownerName, data?.tagName]}
                auctionTypeText={getAuctionTypeText(data.status)}
                imageLink={data?.images}
                auctionDetails={getAcutionPropertyDetails}
                auctionData={data}
                title={data?.title}
                cardtags={data?.tags}
                sellerSlugForRegisteredAuction={data?.seller}
                isPageTypeProfile={isPageTypeProfile}
                isRegisteredCard={isRegisteredCard}
                auctionType={auctionType}
                refetchFollowedAuctions={refetchFollowedAuctions}
                isDesktopSSR={isDesktopSSR}
                cardIndex={cardIndex}
                isEditModeEnable={isEditModeEnable}
              />
              {data?.status?.toLowerCase() === 'cancel' && (
                <div className={styles.cancelStampWrapper}>
                  <Image
                    src={
                      lang === 'en'
                        ? `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/cancelledStampEn.webp`
                        : `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/cancelledStampAr.webp`
                    }
                    alt={'Cancelled Stamp'}
                    fill
                  />
                </div>
              )}
              <div className={`${styles.cardBorder} ${getEditCardAndFooterPaddingClassName('btmPad')}`}>
                <div className={`${styles.cardDetails} ${getFooterBlurClassName()}`}>  
                  <div ref={containerRef} className={styles.price_sqm}>
                    <div className={styles.cardTitleAndLogoContainer}>
                      {data?.seller !== ALL_SELLER_TYPE.INFATH && data.sellerSlug !== ALL_SELLER_TYPE.INFATH ? (
                        <div className={styles.auctionTitle}>
                          {numberWithCommas(
                            Number(
                              (getCurrentHighestBid()) /
                                parseInt(data.landArea),
                            ).toFixed(2),
                          )}{' '}
                          <span>
                            {' '}
                            {t('auctionPopup.SAR')}/{t('auctionBidComponent.SQM').toLowerCase()}
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                      <div
                        className={`${styles.auctionEllipse} ${
                          data?.seller == 'au-infath' || data.sellerSlug == 'au-infath'
                            ? ''
                            : styles.auctionTitleSingleLine
                        }`}
                      >
                        {data?.title}
                      </div>
                      {data?.sellerSlug === ALL_SELLER_TYPE.WASALT || isRegisteredCard ? (
                        <p className={styles.CardAddress}>{data?.address}</p>
                      ) : (
                        <p className={styles.CardAddress} onClick={stopMobileRouting}>
                          {renderAddress()}
                        </p>
                      )}
                    </div>
                    {data?.seller == 'au-infath' || data.sellerSlug == 'au-infath' ? (
                      <img className={styles.brokerfirmLogo} src={data?.brokerageFirmLogo} />
                    ) : null}
                  </div>
                  <div className={styles.content}>
                    {isSellerInfath && !isPageTypeProfile ? (
                      <div className={` ${styles.verticalattribute}`}>
                        <InfathAttributes
                          styles={{
                            attribute__count_style: styles.attribute__count,
                            attribute__type_style: styles.type,
                            attribute__image_style: `${styles.attribute__image} ${styles.image}`,
                          }}
                          attributes={getAttributes()}
                          showAttributeName={false}
                          auctionData={data}
                        />
                      </div>
                    ) : (
                      <div className={styles.attribute}>
                        <Attributes
                          styles={{
                            attribute__count_style: styles.attribute__count,
                            attribute__type_style: styles.attribute__type,
                            attribute__image_style: styles.attribute__image,
                            infathAuctionIcon: getInfathAuctionIcon(),
                          }}
                          attributes={data?.attributes}
                          showAttributeName={false}
                        />
                      </div>
                    )}

                    <CardFooter
                      styles={styles}
                      isRegisteredCard={isRegisteredCard}
                      leftHeaderText={leftHeaderTextForFooter()}
                      rightHeaderText={rightHeaderTextForFooter()}
                      leftHeaderTextStyle={styles.font_12px}
                      rightHeaderTextStyle={styles.font_12px}
                      leftContent={getLeftContentForFooter()}
                      rightContent={getRightContentForFooter()}
                      showleftPart={true}
                      showRightPart={true}
                    />
                  </div>
                </div>  
              </div>

              {renderStrip()}
            </div>
          </a>
        </Link>
      </div>
      {ShowTransitionCards && data?.paymentsourceType == '9' && (
        <EWalletAuctionTransactionDetailPopup
          onClose={showTransactionHandler}
          data={TransitionData}
          transationDetails={data}
        />
      )}
      {showEditRejectPopup && <AuctionRejectEditPopup isEditEnable={isEditModeEnable} closeHandler={CloseEditPopUp}/>}
    </>
  )
}
export default AuctionCard
