import IconoGraphy from '@/components/Common/IconoGraphy'
import { numberWithCommas } from '@/utils/utils'

const Attributes = ({
  attributes,
  showAttributeName,
  styles: { attribute__image_style,infathAuctionIcon, attribute__type_style, attribute__count_style, attribute__name_style },
}) => {
  attributes = attributes?.filter((item) => {
    return item.value
  })
  return attributes
    ? attributes?.map((item) => {
        if (item.value) {
          return (
            <div key={item.key} className={attribute__type_style}>
              <div className={`${attribute__image_style} ${infathAuctionIcon} `} >
                <IconoGraphy
                  iconClass={`icon-${item.iconClass}`}
                  iconColor={'color_gray'}
                  fontSize={'f_16'}
                ></IconoGraphy>
                <span className={attribute__count_style} data-unit={item.unit}>
                  {numberWithCommas(item.value)}
                </span>
              </div>
              {showAttributeName && <div className={attribute__name_style}>{item.name.toLowerCase()}</div>}
            </div>
          )
        }
      })
    : []
}
export default Attributes
