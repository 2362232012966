import styles from './footer.style.module.scss'
const CardFooter = ({
  leftHeaderText,
  leftHeaderTextStyle,
  rightHeaderText,
  rightHeaderTextStyle,
  leftContent,
  rightContent,
  showleftPart,
  showRightPart,
  isRegisteredCard,
  showOnlyLeftHeader = false,
  showOnlyRightHeader = false,
}) => {

  return (
    <div className={`${styles.aCardF} ${styles.footer_wrapper} ${isRegisteredCard && styles.topContainer}` }>
      {showleftPart && (
        <div className={styles.w_50}>
          <div className={leftHeaderTextStyle}> {leftHeaderText}</div>
          {!showOnlyLeftHeader && <div className={styles.footer}>{leftContent}</div>}
        </div>
      )}
      {showRightPart && (
        <div className={styles.w_50 + ' ' + styles.text_end}>    
          <div className={rightHeaderTextStyle}>{rightHeaderText}</div>      
          {!showOnlyRightHeader && rightContent}
        </div>
      )}
    </div>
  )
}
export default CardFooter
