import React, { useEffect } from 'react'
import styles from './style_tooltip_new.module.scss'

const Tooltip_new = (props) => {
  const { message, right, customStyles, left, tipPosition, dir, elementStyles, dynamicTooltip, id = '' } = props

  const changeStyle = (elementStylesParam) => {
    const element = document.getElementById(`tooltip_new${id}`)
    if (element){
      const styleEntries = [
        ['left', 'left'],
        ['right', 'right'],
        ['transform', 'transform'],
        ['top', 'top'],
        ['maxWidth', 'max-width'],
        ['visibility', 'visibility'],
      ];
      styleEntries.forEach(([key, style]) => {
        if (elementStylesParam?.[key] !== undefined) {
          element.style[style] = elementStylesParam[key];
        }
      });
      const arrowStyleEntries = [
        ['left', '--left'],
        ['right', '--right'],
        ['transform', '--transform'],
        ['top', '--top'],
        ['display', '--display'],
      ];
      const afterElement = window.getComputedStyle(element, '::after');
      if (afterElement) {
        arrowStyleEntries.forEach(([key, style]) => {
          if (elementStylesParam?.arrowStyle?.[key] !== undefined) {
            element.style.setProperty(style, elementStylesParam.arrowStyle[key]);
          } else if (key === 'display') {
            element.style.setProperty(style, 'inherit');
          }
        });
      }
    }
  }

  useEffect(() => {
    if(dynamicTooltip){
      changeStyle(elementStyles)
    }else{
      const element = document.getElementById(`tooltip_new${id}`)
      if (element) {
        element.style.visibility = 'visible'
      }
    }
  }, [elementStyles])

  return (
    <div
      id={`tooltip_new${id}`}
      dir={dir}
      className={`${styles.tooltipWrapper} ${styles[tipPosition]} ${right ? styles.right : ''} ${
        left ? styles.left : ''
      }`}
      style={{ customStyles }}
    >
      <p dangerouslySetInnerHTML={{ __html: message }} dir={dir}></p>
    </div>
  )
}

export default Tooltip_new
